export enum Role {
    USER = "USER", 
    ADMIN = "ADMIN",
    MODERATOR = "MODERATOR",
}

export interface User {
    id?: number;
    name: string;
    email: string;
    password?: string;
    cellphone: string;
    plataform: string;
    role: Role;
    channels?: [];
    active: number;
    createAt?: Date;
    updateAt?: Date;
    token?: string;
}
