import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import {SessionService} from "@app/shared/services/session.service";
import {AuthService} from "@app/shared/services/auth/auth.service";

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(
    private serv: SessionService,
    private auth: AuthService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isLoggedIn = this.serv.getSessionUser()?.token;
    if (isLoggedIn) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${this.serv.getSessionUser()?.token}` }
      });
    }
    return next.handle(request).pipe(catchError(x => this.handleAuthError(x, request)));
  }

  private handleAuthError(err: HttpErrorResponse, request: HttpRequest<unknown>): Observable<any> {
    if ((err.status === 401 || err.status === 403) && !request.url.includes('api.telegram.org/')) {
      this.serv.removeSessionUser();
      this.auth.removerUser();
      this.router.navigate(['/auth/login']);
    }
    return throwError(err);
  }

}
