import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {Login} from "@app/shared/interfaces/login";
import {User} from "@app/shared/interfaces/user";

const urlLogin = environment.url_api + '/area-externa/entrar';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  private user!: object;

  postForLogin(login: Login): Observable<User> {
    return this.http.post<User>(urlLogin, login);
  }

}
