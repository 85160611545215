import { Component, OnInit, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirection } from '@app/shared/types/modal-direction';
import {AuthService} from "@app/shared/services/auth/auth.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'nav-config',
    templateUrl: './nav-config.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.header-nav-item]': 'true'
    },
    providers: [ ToastrService ]
})
export class NavConfigComponent implements OnInit {
    modalRef: BsModalRef;

    constructor(private modalService: BsModalService, private authService: AuthService, private router: Router,
                private toastr: ToastrService) {}

    ngOnInit () {

    }

    openQuickPanel(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show( template, Object.assign({}, { class: `${ModalDirection.Right}` }));
    }

    logout() {
      this.authService.logout();
      this.modalRef.hide();
      this.router.navigate(['/auth/login']);
      this.toastr.success('Redirecionando...')
    }
}
