import { NavMenu } from "@app/shared/types/nav-menu.interface";

const dashboard: NavMenu[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    translateKey: "NAV.DASHBOARD",
    type: "item",
    iconType: "feather",
    icon: "icon-home",
    key: "dashboard",
    submenu: [],
  },
  {
    path: "/channels",
    title: "Meus Canais",
    translateKey: "Meus Canais",
    type: "item",
    iconType: "feather",
    icon: "icon-radio",
    key: "channels",
    submenu: [],
  },
  {
    path: "/statistics",
    title: "Relatório dos Canais",
    translateKey: "Relatório dos Canais",
    type: "item",
    iconType: "feather",
    icon: "icon-pie-chart",
    key: "statistics",
    submenu: [],
  },
];

export const navConfiguration: NavMenu[] = [...dashboard];
