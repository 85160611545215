import { Injectable } from '@angular/core';
import {Games} from "@app/shared/interfaces/games";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  navSidebarClass: boolean = true;
  hamburgerClass: boolean = false;
  mainteningMode: string[] = [];

  private gamesInMemory: Games[] = [];

  private plataforms = new Map<string, string[]>([
    ['AVIATOR', ['AVIATOR_REALSBET']],
    ['FOOTBALL', ['FOOTBALL_STUDIOS']],
    ['SPACEMAN', ['SPACEMAN_REALSBET']],
    ['BACBOO', ['BACBOO']],
    ['PENALT', ['PENALT']],
    ['PENALT_V2', ['PENALT_V2']],
    ['MINES', ['MINES']],
    ['ROULLET', ['ROULLET']],
    ['DRAGON_TIGER', ['DRAGON_TIGER']],
    ['FORTUNE_TIGER', ['FORTUNE_TIGER']],
    ['CRASH', ['CRASH_BETSAC']],
    ['DOUBLE', ['DOUBLE_BETSAC', 'DOUBLE_DOUBLE']],
    ['LUVA', ['LUVA_SUPER']],
    ['STOCK MARKET', ['STOCK_MARKET']],
  ]);

  constructor() { }

  toggleSidebarClass() {
    return this.navSidebarClass = !this.navSidebarClass  ;
  }
  toggleHamburgerClass() {
    return this.hamburgerClass = !this.hamburgerClass  ;
  }

  getGamesInMemory():Games[] {
    return this.gamesInMemory;
  }

  setGamesInMemory(object: Games[]):void {
    this.gamesInMemory = object;
  }
  getPlataformsWithGame(game: string):string[] | undefined {
    return this.plataforms.get(game);
  }

  getGamesType(): string[]{
    return Array.from(this.plataforms.keys());
  }

  getGamesWithPlataform(): string[]{
    let games: string[] = [];
    this.plataforms.forEach(customer => {
      customer.forEach(game => {
        games.push(game);
      })
    })
    return games;
  }
}
