import { Routes } from '@angular/router';

export const APP_LAYOUT_ROUTES: Routes = [
    //Dashboard
    {
        path: 'dashboard',
        loadChildren: () => import('../views/dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
      path: 'channels',
      loadChildren: () => import('../views/pages/channels/channels.module').then(m => m.ChannelsModule),
    },
    {
        path: 'statistics',
        loadChildren: () => import('../views/pages/statistics/statistics.module').then(m => m.StatisticsModule),
    },
];
