import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { SessionService } from '../session.service';
import {SharedService} from "@app/shared/services/shared.service";
import {Role, User} from "@app/shared/interfaces/user";
import {LoginService} from "@app/shared/services/login.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private sessionService: SessionService, private sharedService: SharedService,
              private loginService: LoginService) {}

  isAuthenticate: boolean = false;

  user: User | undefined;

  login(email: string, password: string): Observable<{ success: boolean; user: User | undefined; }>{
    var subject = new Subject<{ success: boolean; user: User | undefined; }>();

    this.loginService.postForLogin({email, password})
      .subscribe(
        res => {
          this.user = res;
          subject.next({ success: true, user: this.user });
          this.saveSession();
        },
        err => {
          subject.next({ success: false, user: undefined });
        }
      );
      return subject.asObservable();
  }

  logout() {
    this.user = undefined;
    this.sessionService.removeSessionUser();
    this.unloadUsersAndChannels();
    return of({ success: true, user: this.user });
  }

  isLoggedIn() {
    const userSession = this.sessionService.getSessionUser();

    if (this.user != undefined) {
      return true;
    } else if (userSession != undefined) {
      this.user = userSession;
      return true;
    } else {
      this.unloadUsersAndChannels();
      return false;
    }
  }

  getRole(): Role | undefined {
    return this.user?.role;
  }

  getPlataform(): string | undefined {
    return this.user?.plataform;
  }

  getUser(): User {
    return this.user!;
  }

  saveSession() {
    this.sessionService.setSessionUser(this.user!);
  }

  private unloadUsersAndChannels(): void {
    //this.sharedService.setUsersInMemory([]);
    this.sharedService.setGamesInMemory([]);
  }

  removerUser() {
    this.user = undefined;
  }

}
