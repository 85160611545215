import { Injectable } from '@angular/core';
import {User} from "@app/shared/interfaces/user";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private readonly SESSION_TOKEN_PROP = 'session-token';

  constructor() { }

  public getSessionUser(): User | undefined {
    let session = localStorage.getItem(this.SESSION_TOKEN_PROP);
    if (session) {
      try {
        const decoded = JSON.parse(atob(session));
        return decoded;
      } catch {
        this.removeSessionUser();
        return undefined;
      }
    }
    return undefined;
  }

  public setSessionUser(user: User): void {
    const encoded = btoa(JSON.stringify(user));
    localStorage.setItem(this.SESSION_TOKEN_PROP, encoded);
  }

  public removeSessionUser(): void {
    localStorage.removeItem(this.SESSION_TOKEN_PROP);
  }

}
